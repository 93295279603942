<template>
  <div class="chessRescueMoney">
    <div v-title
         data-title="棋牌负利"></div>
    <!-- 
    <van-nav-bar
      title="棋牌救援"
      left-arrow
      :fixed="true"
      @click-left="gotoback"
    /> -->
    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/bg-ChessSave.jpg`" />
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img">
        <img src="../assets/img/pcbb/rules_1.png"
             v-if="cueTheme == 'dattheme'" />
        <img :src="`${imgUrl}pcbb/rules.png`"
             v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 我的金额 -->
        <div class="rule-content"
             style="margin-bottom:20px">
          <div class="rule-content-title2"
               style="margin-top:0"></div>
          <table>
            <tr>
              <td style="border-top:1px solid #eee;border-bottom:1px solid #eee;background: linear-gradient(90deg, #3D73FA, #63B3FC);;color: #fff; width: 150px;">
                更新时间
              </td>
              <td style="border-top:1px solid #eee;border-bottom:1px solid #eee;background-color: var(--w-bgcolor);">
                {{ datalist.update_time }}
              </td>
            </tr>
            <tr>
              <td style="background: linear-gradient(90deg, #3D73FA, #63B3FC);color: #fff;border-bottom:1px solid #eee">
                瓦力棋牌
              </td>
              <td style="border-top:1px solid #eee;border-bottom:1px solid #eee;background-color: var(--w-bgcolor);">
                {{ datalist.wl_beat }} 元
              </td>
            </tr>
            <tr>
              <td style="background: linear-gradient(90deg, #3D73FA, #63B3FC);color: #fff;border-bottom:1px solid #eee">
                幸运棋牌
              </td>
              <td style="border-top:1px solid #eee;border-bottom:1px solid #eee;background-color: var(--w-bgcolor);">
                {{ datalist.xy_beat }} 元
              </td>
            </tr>
            <tr>
              <td style="background: linear-gradient(90deg, #3D73FA, #63B3FC);color: #fff;border-bottom:1px solid #eee">
                贝宝棋牌
              </td>
              <td style="border-top:1px solid #eee;border-bottom:1px solid #eee;background-color: var(--w-bgcolor);">
                {{ datalist.kx_beat }} 元
              </td>
            </tr>
            <tr>
              <td style="background: linear-gradient(90deg, #3D73FA, #63B3FC);color: #fff;border-bottom:1px solid #eee">
                OB棋牌
              </td>
              <td style="border-top:1px solid #eee;border-bottom:1px solid #eee;background-color:var(--w-bgcolor);">
                {{ datalist.ob_beat }} 元
              </td>
            </tr>
            <tr>
              <td style="background: linear-gradient(90deg, #3D73FA, #63B3FC);;color: #fff;border-bottom:1px solid #eee">
                开元棋牌
              </td>
              <td style="border-top:1px solid #eee;border-bottom:1px solid #eee;background-color: var(--w-bgcolor);">
                {{ datalist.ky_beat }} 元
              </td>
            </tr>
            <tr>
              <td style="background: linear-gradient(90deg, #3D73FA, #63B3FC);color: #fff;border-bottom:1px solid #eee">
                乐游棋牌
              </td>
              <td style="border-top:1px solid #eee;border-bottom:1px solid #eee;background-color: var(--w-bgcolor);">
                {{ datalist.leg_beat }} 元
              </td>
            </tr>
            <tr>
              <td style="background: linear-gradient(90deg, #3D73FA, #63B3FC);color: #fff;">
                棋牌总流水
              </td>
              <td style="background-color:var(--w-bgcolor);">
                {{ datalist.beat }} 元
              </td>
            </tr>
          </table>
        </div>

        <!-- 活动条件 -->
        <div class="rule-condition">
          <div class="rule-condition-title"></div>
          <div class="rule-acontents">
            <div class="title-item">
              <p>活动对象:</p>
              <span style="color:var(--li-colortext);">贝宝体育全站会员</span>
            </div>
            <div class="title-item">
              <p>指定场馆:</p>
              <span style="color:var(--li-colortext);">瓦力棋牌、幸运棋牌、贝宝棋牌、OB棋牌、KY棋牌、乐游棋牌</span>
            </div>
            <div class="title-item">
              <p>活动时间:</p>
              <span style="color:var(--li-colortext);">长期</span>
            </div>
            <div class="title-item">
              <p>活动要求:</p>
              <span style="color:var(--li-colortext);">流水要求</span>
            </div>
            <div class="title-item">
              <p>申请规则:</p>
              <span style="color:var(--li-colortext);">
                在活动开启期间内，前往指定棋牌场馆进行游戏，负盈利满足申请条件后即可申请彩金，工作人员审核后系统自动发放。</span>
            </div>
            <div style="color:red;font-size:14px;line-height: 24px;">
              注:假设A场馆盈利2000，B场馆负盈利1000，此时总和为盈利1000则不满足负盈利标准。
            </div>
          </div>
        </div>

        <!-- 活动内容 -->
        <div class="rule-content">
          <div class="rule-content-title"></div>
          <table>
            <thead>
              <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc)">
                <td style="
                              color: #fff;
                              border-top-left-radius: 10px;
                              border-right: 1px solid #e2d2bf;
                            ">
                  负盈利
                </td>
                <td style="color: #fff; border-right: 1px solid #e2d2bf">
                  救援金
                </td>
                <td style="color: #fff; border-top-right-radius: 10px">
                  流水倍数
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in datalist.config"
                  :key="index">
                <td>{{ item.loss_money }}</td>
                <td>{{ item.bonus }}</td>
                <td v-if="index === 0"
                    :rowspan="datalist.config.length">
                  {{datalist.wlimit}}倍
                </td>
              </tr>
            </tbody>
          </table>

          <div class="btn-box"
               @click="applyWay">立即申请</div>
        </div>

        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1.</span>
                本活动仅限棋牌场馆（瓦力棋牌、幸运棋牌、贝宝棋牌、OB棋牌、KY棋牌、乐游棋牌）参与。
              </li>
              <li>
                <span>2.</span>
                每位会员在活动期间内每日只能申请一次，当日申请是按统计昨日的棋牌场馆(指定游戏场馆内的流水全部相加)。
              </li>
              <li>
                <span>3.</span>
                本活动流水于次日17:00后更新显示，申请时间是每天17:00到晚上23:59，需要玩家本人申请，每天都可以领取一次。
              </li>
              <li>
                <span>4.</span>
                本活动只针对娱乐性质的会员，同一手机号码、电子邮箱、相同银行卡、同一个IP地址、同一通信地址、同一台设备，只能由一位会员使用，若发现重复行为，将无限期保留审核、扣回礼品及所产生的利润之权利。
              </li>
              <li>
                <span>5.</span>
                本活动中任何用户或团体以不正常的方式套取活动优惠，我司保留在不通知的情况下冻结或关闭账号使用的权力，并不退还款项，且用户会被列入黑名单。若发现有套利客户，对冲，或不诚实获取盈利之行为，将取消其优惠资格。
              </li>
              <li>
                <span>6.</span>
                为避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>
  </div>
</template>

<script>
import { getdata } from '../network/api'
import { Toast } from 'vant'
export default {
  data() {
    return {
      datalist: [],
      isshow: false,
      cueTheme: '',
    }
  },
  mounted() {
    this.getdata()
    this.cueTheme = window.localStorage.getItem('cueTheme')
  },
  methods: {
    changeisshow() {
      clearInterval(this.timer)
      this.isshow = true
      this.timer = setTimeout(() => {
        this.isshow = false
      }, 3000)
    },
    gotoback() {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true',
            },
          })
        })
      }
    },
    getdata() {
      let data = '?type=ChessSave'
      getdata(data).then((res) => {
        if (res.status_code === 200) {
          this.datalist = res.message
        }
      })
    },
    applyWay() {
      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          // Toast('该活动仅在app开发,请下载APP')
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'vipActapplyobject',
                actobject: {
                  act: 'ChessSave',
                },
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else {
          uni.postMessage({
            data: {
              urlApply: 'ChessSave',
            },
          })
          // uni.postMessage({
          //   data: {
          //     urlApply: 'vipActapplyobject',
          //     actobject: {
          //       act: 'ChessSave',
          //     },
          //   },
          // })
        }
      })
    },
  },
}
</script>

<style lang="less">
@import '../assets/css/rulestyle.less';

body {
  background-color: #f8fafa;
}

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000;
}

.chessRescueMoney {
  .rightbox {
    position: fixed;
    top: 150px;
    right: 0;
    background: linear-gradient(0deg, #2a6afd, #63c6ff);
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    padding: 5px 5px 5px 0;
    color: #fff;
    z-index: 11000;

    .content {
      display: flex;
      align-items: center;

      > div {
        &:nth-child(1) {
          min-width: 54px;
          height: 54px;
          border: 4px solid #fff;
          border-radius: 50%;
          margin-left: 5px;
          margin-right: 5px;

          img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
          }
        }

        .primary_color {
          font-size: 11px;
          line-height: 1.2em;
        }
      }
    }
  }

  .btn-box {
    height: 42px;
    line-height: 42px;
    text-align: center;
    border-radius: 5px;
    margin: 25px 0px 15px 0px;
    color: #fff;
    background: linear-gradient(0deg, #2a6afd, #63c6ff);
  }
}
</style>
